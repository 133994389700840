import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

// ***** user routes *****
const adminsRoute = {
  categorySlug: "user_management",
  name: "Admins",
  link: ROUTE_CONSTANTS.DASHBOARD.USERS.ADMINS.ABSOLUTE,
  badgeTooltip: "Admin List",
  icon: SupportAgentIcon,
  permissionKey: "Get_Amin_List",
};
const customerRoute = {
  categorySlug: "user_management",
  name: "Customers",
  link: ROUTE_CONSTANTS.DASHBOARD.USERS.CUSTOMERS.ABSOLUTE,
  badgeTooltip: "Customer List",
  icon: PeopleAltIcon,
  permissionKey: "Get_Customer_List",
};
const proRoute = {
  categorySlug: "user_management",
  name: "Pro",
  link: ROUTE_CONSTANTS.DASHBOARD.USERS.PRO.ROOT.ABSOLUTE,
  badgeTooltip: "Pro List",
  icon: PeopleAltIcon,
  permissionKey: "Get_Pro_List",
};
// ***** catalog routes *****

const categoryRoute = {
  categorySlug: "catalog",
  name: "Category",
  link: ROUTE_CONSTANTS.DASHBOARD.CATALOG.CATEGORY.ROOT.ABSOLUTE,
  badgeTooltip: "Category List",
  icon: PeopleAltIcon,
  permissionKey: "Get_Category_List",
};
const typeRoute = {
  categorySlug: "catalog",
  name: "Services",
  link: ROUTE_CONSTANTS.DASHBOARD.CATALOG.TYPE.ROOT.ABSOLUTE,
  badgeTooltip: "Service List",
  icon: PeopleAltIcon,
  permissionKey: "Get_Service_List",
};

// ***** questions routes *****

const questionsRoute = {
  categorySlug: "questionnaire",
  name: "Questionnaire",
  link: ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.ROOT.ABSOLUTE,
  badgeTooltip: "questionnaire List",
  icon: PeopleAltIcon,
  permissionKey: "Get_Questionnaire_List",
};

// ***** booking routes *****

const bookingRoute = {
  categorySlug: "booking",
  name: "Book Requests",
  link: ROUTE_CONSTANTS.DASHBOARD.BOOKING.BOOKREQUESTS.ROOT.ABSOLUTE,
  badgeTooltip: "Book Requests",
  icon: PeopleAltIcon,
  permissionKey: "Get_Book_Request_List",
};

const bidsRoute = {
  categorySlug: "booking",
  name: "Bids List",
  link: ROUTE_CONSTANTS.DASHBOARD.BOOKING.BIDS.ROOT.ABSOLUTE,
  badgeTooltip: "Bids List",
  icon: PeopleAltIcon,
  permissionKey: "Get_Bid_List",
};
// ***** bidpricing routes *****
const bidPricingRoute = {
  categorySlug: "bid-pricing",
  name: "Payment History",
  link: ROUTE_CONSTANTS.DASHBOARD.BIDPRICING.PAYMENTHISTORY.ROOT.ABSOLUTE,
  badgeTooltip: "Payment History",
  icon: PeopleAltIcon,
  permissionKey: "Get_Payment_History_List",
};

// ***** system routes *****

const staticPagesRoute = {
  categorySlug: "system",
  name: "Static Pages",
  link: ROUTE_CONSTANTS.DASHBOARD.SYSTEM.STATIC_PAGES.ROOT.ABSOLUTE,
  badgeTooltip: "Static Pages",
  icon: PeopleAltIcon,
  permissionKey: "Get_Static_Page_List",
};

const walletLimitRoute = {
  categorySlug: "system",
  name: "Pro Wallet Limit",
  link: ROUTE_CONSTANTS.DASHBOARD.SYSTEM.WALLETLIMIT.ROOT.ABSOLUTE,
  badgeTooltip: "Pro Wallet Limit",
  icon: PeopleAltIcon,
  permissionKey: "Get_Setting",
};

const adminEmailRoute = {
  categorySlug: "system",
  name: "Admin Email",
  link: ROUTE_CONSTANTS.DASHBOARD.SYSTEM.ADMINEMAIL.ROOT.ABSOLUTE,
  badgeTooltip: "Admin Email",
  icon: PeopleAltIcon,
  permissionKey: "Get_Setting",
};

const unitMeasurement = {
  categorySlug: "system",
  name: "Unit Of Measurement",
  link: ROUTE_CONSTANTS.DASHBOARD.SYSTEM.UNIY_MEASUREMENT.ROOT.ABSOLUTE,
  badgeTooltip: "Unit Of Measurement",
  icon: PeopleAltIcon,
  permissionKey: "Get_Units",
};

const inAppSubscription = {
  categorySlug: "system",
  name: "In-App Subscription",
  link: ROUTE_CONSTANTS.DASHBOARD.SYSTEM.INAPPSUBSCRIPTION.ROOT.ABSOLUTE,
  badgeTooltip: "In-App Subscription",
  icon: PeopleAltIcon,
  permissionKey: "Get_Setting",
};

const administration = {
  categorySlug: "system",
  name: "Permissions",
  link: ROUTE_CONSTANTS.DASHBOARD.SYSTEM.ADMINISTRATION.ROOT.ABSOLUTE,
  badgeTooltip: "Permissions",
  icon: PeopleAltIcon,
  permissionKey: "Role_Controller",
};

const smsGateway = {
  categorySlug: "system",
  name: "SMS Gateway",
  link: ROUTE_CONSTANTS.DASHBOARD.SYSTEM.SMS_GATEWAY.ROOT.ABSOLUTE,
  badgeTooltip: "SMS Gateway",
  icon: PeopleAltIcon,
  permissionKey: "Get_Setting",
};
export const sidebarRoutesObjects = {
  adminsRoute,
  customerRoute,
  proRoute,
  categoryRoute,
  typeRoute,
  questionsRoute,
  bookingRoute,
  bidsRoute,
  bidPricingRoute,
  staticPagesRoute,
  unitMeasurement,
  walletLimitRoute,
  adminEmailRoute,
  inAppSubscription,
  administration,
  smsGateway,
};
